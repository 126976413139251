@import '_mixins';
@import '_reset';

@include fontFace('OpenSans', './../fonts/OpenSans');
@include fontFace('OpenSansLight', './../fonts/OpenSans-Light');
@include fontFace('OpenSansSemiBold', './../fonts/OpenSans-Semibold');
@include fontFace('OpenSansBold', './../fonts/OpenSans-Bold');
@include fontFace('OpenSansExtrabold', './../fonts/OpenSans-Extrabold');

$white-color:					#fff;
$black-color: 					#000;
$blue-color:					#0e6e9e;
$dblue-color:					#2a3548;
$green-color:					#03a944;
$ggreen-color:					#039a3e;
$sgreen-color:					#008835;

body {
	background:					url(./../img/bg.jpg);
	@include fontStyle (16px, OpenSans, $dblue-color);
	@include backgroundStyle(center, cover, repeat); }

a {
	color: 						$white-color;
	text-decoration: 			none;

	&:hover {
		color: 					$white-color;
		text-decoration: 		none; } }

.bg-fix {
	position: 					fixed;
	left: 						0px;
	top: 						0px;
	width: 						100%;
	height: 					100%;
	background:					url(./../img/bg.jpg);
	@include backgroundStyle(center, cover, repeat);
	z-index: 					-1; }

.logo {

	span {
		display: 				block;
		@include fontStyle (18px, OpenSans, $blue-color);

		&:nth-child(1) {
			@include fontStyle (18px, OpenSansBold, $blue-color); }

		&:nth-child(2) {
			@include fontStyle (18px, OpenSans, $blue-color); } } }

.contacts {
	text-align: 				right;
	@include fontStyle (20px, OpenSansSemiBold, $dblue-color);

	a {
		display: 				block;
		@include fontStyle (20px, OpenSansSemiBold, $dblue-color);
		@media screen and (max-width: 370px) {
			font-size: 			14px; } } }

.title {
	margin-top: 				50px;
	text-align: 				center;

	h1 {
		@include fontStyle (60px, OpenSansExtrabold, $dblue-color);
		@media screen and (max-width: 1000px) {
			font-size: 			30px; }
		@media screen and (max-width: 370px) {
			font-size: 			20px; } } }

.points {
	margin-top: 				50px;

	.media {
		margin-bottom: 			20px; }

	span {
		@include fontStyle (20px, OpenSansSemiBold, $dblue-color);
		@media screen and (max-width: 1000px) {
			margin-top: 		0px!important;
			font-size: 			16px; } }

	img {
		@media screen and (max-width: 1000px) {
			width: 				30px; } } }

.cta {
	margin-top: 				50px; }

.cta-box {
	text-align: 				center; }

h5 {
	@include fontStyle (25px, OpenSansBold, $dblue-color);
	@media screen and (max-width: 370px) {
			font-size: 			18px; } }

.button {
	display: 					inline-block;
	padding: 					20px 50px;
	background: 				linear-gradient(to bottom, $green-color, $ggreen-color);
	box-shadow: 				$sgreen-color 0 5px 0 0;
	margin-top: 				20px;
	margin-bottom: 				30px;
	@include borderRadius(5px);
	@include fontStyle (20px, OpenSansBold, $white-color);
	@include transitionStyle(400ms);
	@media screen and (max-width: 430px) {
			font-size: 			15px;
			padding: 			10px 20px; }

	&:hover {
		box-shadow: 			none; } }

.ajax-form {
	margin-top: 				20px; }

input {
	width: 						100%;
	max-width: 					400px;
	border: 					none;
	outline: 					none;
	text-align: 				center;
	margin-bottom: 				10px;
	padding: 					10px 0px;
	@include borderRadius(5px);
	@media screen and (max-width: 430px) {
			font-size: 			15px!important; }

	&[type="submit"] {
		background: 			linear-gradient(to bottom, $green-color, $ggreen-color);
		box-shadow: 			$sgreen-color 0 5px 0 0;
		margin-bottom: 			30px;
		@include fontStyle (20px, OpenSansBold, $white-color);
		@include transitionStyle(400ms);

		&:hover {
			box-shadow: 		none; } }

	&[type="text"] {
		background: 			$white-color;
		@include fontStyle (20px, OpenSans, $blue-color); } }

.footer {
	margin-top: 				50px;
	padding: 					20px 0px;
	background: 				$dblue-color;

	span, a {
		display: 				block;
		@include fontStyle (14px, OpenSansLight, $white-color); } }

.contacts-footer {
	text-align: 				right; }
